import React from 'react';
import Layout from '@components/layout';
import SecuredComponent from '@components/secured';
import SetupDevice from '@components/setup_device';
import { useAuth } from '@hooks/useAuth';
import { SystemNotification } from '@components/SystemNotification';

function AppPage(){
    
    const auth = useAuth();

    return (
        <Layout>
            <SystemNotification />
            <SecuredComponent component={SetupDevice} path="/setup-device" />
        </Layout>
    );
};

export default AppPage;
