import React from 'react';
import { SEO } from '@components/SEO';
import { MenuComponent } from '@components/Menu';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';

const SimpleComponent = ({title, children}) => {
    
    return (
        <div className="component">
            <h2 className="component__header">{title}</h2>
            <div className="component__body">
                {children}
            </div>
        </div>
    );
};

function SetupDevice(){

    const currentGym = useSelector(state => state.currentGym);

    if(!currentGym){
        return null;
    }

    return (
        <>
            <SEO title="Setup Device" />
            <div className="main__layout">
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">
                    <div className="component__wrapper">
                        <div className="component__inner">
                            <h1 className="component__wrapper__header">Setting Up Your Reception Device</h1>
                            <div className="component__container">
                                <SimpleComponent title="Your Gym QR Code">
                                    <div className="page__content">
                                        <p>This is a unique QR code just for your gym, it will allow you to sync your chosen device(s) to your gym, to allow members to checkin.</p>
                                        <QRCode 
                                            value={currentGym.gym_hash}
                                            size={320}
                                            />
                                    </div>
                                </SimpleComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right__column"></div>
            </div>
        </>
    );
}

export default SetupDevice;