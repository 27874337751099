import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../services/user';
import { useLocation } from '@reach/router';

function SecuredComponent({ component: Component, ...rest }){
    
    const location = useLocation();
    const isBrowser = typeof window !== 'undefined';

    if( (!isLoggedIn() && location.pathname !== '/' && isBrowser) ){
        navigate('/');
        return null;
    }

    return (
        <Component {...rest} />
    );
};

export default SecuredComponent;